import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const vendorTypeComputed = {
    ...mapState('vendortype', {
        vendortype:(state) => state.vendor_types,
    }),
    ...mapGetters('vendortype', ['getVendorType'])
}

// export property actions
export const vendorTypeMethods = mapActions('vendortype', ['allVendorType','createVendorType', 'updateVendorType', 'deleteVendorType',
])
